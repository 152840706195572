.datepaginator .pagination {
  li {
    a, span {
      position: relative;
      float: left;
      padding: 6px 12px;
      line-height: 1.42857;
      text-decoration: none;
      color: #337ab7;
      background-color: #fff;
      border: 1px solid #ddd;
      margin-left: -1px;
    }
  }
}

.tag {
  font-size: 13px;
  padding: 2px 5px;
  background-color: #36c6d3;
  color: #fff;
  margin-right: 5px;
}