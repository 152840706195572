.channel-reports{
  .accordion-item{
    border: 1px solid #a1a5b7 !important;
    border-radius: 8px !important;
    overflow: hidden;

    & + .accordion-item{
      margin-top: 8px;
    }
  }
}