.react-time-picker input{
  color: white;
}

.react-time-picker__button svg{
  filter: invert(91%) sepia(99%) saturate(2%) hue-rotate(354deg) brightness(107%) contrast(101%);;
}

.react-clock__face{
  border-color: white;
}

.react-time-picker__clock {
  background-color: #1b1b29;
}
.react-clock__face div{
  background-color: white;
}
.react-clock__hand__body{
    background-color: white;
  }

  .rdrMonth {
    width: 100% !important;
  }

  .swal2-title{
    color: black !important;
  }